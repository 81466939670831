<template>
  <div>
    <Row v-if="allowEdit">
      <i-col span="24" class="m-b-5">
        <a style="float: right; line-height: 30px" @click="handleChoiceDevices"
          >+添加租赁空间</a
        >
      </i-col>
    </Row>
    <Table
      size="small"
      :columns="columnSpacedata"
      :data="tableData"
      stripe
    ></Table>

    <choiceSpace
      ref="choiceSpaceModal"
      @on-success="reloadInvestSpaceArray"
      :chkSpaceArray="tableData"
      :investmentId="investId"
    ></choiceSpace>
    <deviceList ref="deviceListModal"></deviceList>
  </div>
</template>

<script>
import deviceList from './DeviceList.vue'
import choiceSpace from './choiceSpace.vue'

import { deleteSpace, listSpace } from '@/api/invest/invest'
import { spaceDeviceList } from '@/api/product/leaseSpace'

export default {
  components: {
    choiceSpace,
    deviceList
  },
  props: {
    allowEdit: {
      type: Boolean,
      default: false
    },
    investId: {
      type: Number
    }
  },
  data () {
    return {
      tableData: [],
      columnSpacedata: [
        {
          title: '编码',
          key: 'code'
        },
        {
          title: '空间名称',
          key: 'name'
        },
        { title: '类型', key: 'resourceTypeName' },
        {
          title: '备注',
          key: 'remark'
        },
        {
          title: '操作',
          align: 'center',
          width: 120,
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.initDevices(params.row.spaceId)
                  }
                }
              },
              '资产详情'
            )
            const removeButton = h(
              'a',
              {
                style: {
                  marginRight: '5px',
                  color: 'red'
                },
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: '操作确认？',
                      content:
                        '<p>确定要移除【 ' + params.row.name + '】？</p>',
                      loading: true,
                      onOk: () => {
                        deleteSpace({
                          investId: this.investId,
                          investSpaceId: params.row.id
                        })
                          .then((res) => {
                            if (res && !res.errcode) {
                              this.$Notice.success({ desc: '操作成功' })
                              this.reloadInvestSpaceArray()
                            }
                            this.$Modal.remove()
                          })
                          .catch((error) => {
                            this.$Modal.remove()
                            this.$Notice.error({
                              title: '服务器请求错误',
                              desc: error
                            })
                          })
                      }
                    })
                  }
                }
              },
              '移除'
            )

            return h('div', [detailButton, this.allowEdit ? removeButton : ''])
          }
        }
      ]
    }
  },
  created () {
    this.reloadInvestSpaceArray()
  },
  mounted () {},
  methods: {
    initDevices (spaceId) {
      const that = this
      spaceDeviceList({ spaceId: spaceId }).then((res) => {
        that.$refs.deviceListModal.showModal(res)
      })
    },

    // 新加租赁空间
    handleChoiceDevices () {
      this.$refs.choiceSpaceModal.showModal()
    },
    // 加载已经加入项目的租赁空间
    reloadInvestSpaceArray () {
      listSpace({ investId: this.investId }).then((res) => {
        this.tableData = res
      })
    }
  },
  watch: {},
  computed: {}
}
</script>
