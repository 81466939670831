<template>
  <div>
    <!-- <Row v-if="!allowEdit">
      <i-col span="24" class="text-right">
        <i-button
          type="primary"
          class="m-r-5"
          size="small"
          @click="showProductPublicityModal"
          >项目结果公示</i-button
        >
      </i-col>
    </Row> -->
    <div style="font-size: 14px">基础信息</div>
    <Row class="p-b-5 p-t-10" style="line-height: 24px">
      <i-col span="24"
        ><span class="title">项目名称 </span>{{ investmentInfo.name }}</i-col
      >
      <i-col span="6"
        ><span class="title">业态规划 </span
        >{{ investmentInfo.industryName }}</i-col
      >
      <i-col span="6"
        ><span class="title">招商类别 </span
        >{{ investmentInfo.deviceType }}</i-col
      >
      <i-col span="6"
        ><span class="title">租赁期限(月) </span
        >{{ investmentInfo.tenancy }}个月</i-col
      >
      <i-col span="6"
        ><span class="title">保证金 </span
        >{{ formatMoney(investmentInfo.depositAmount) }}</i-col
      >
      <i-col span="6"
        ><span class="title">报名方式 </span
        >{{ investmentInfo.enrollMethod }}</i-col
      >
      <i-col span="6"
        ><span class="title">开始报名时间 </span
        >{{ investmentInfo.enrollStartDate }}</i-col
      >
      <i-col span="6"
        ><span class="title">结束报名时间 </span
        >{{ investmentInfo.enrollEndDate}}</i-col
      >
      <i-col span="6"></i-col>
      <i-col span="6"
        ><span class="title">创建时间 </span
        >{{ investmentInfo.createTime }}</i-col
      >
      <i-col span="6"
        ><span class="title">状态 </span
        >{{ investmentInfo.statusName}}</i-col
      >
    </Row>
    <Tabs :value="allowEdit?'device':'customer'" class="text-white">
      <TabPane label="报名客户" name="customer" v-if="!allowEdit">
        <Table stripe :columns="columnData" :data="tableData"></Table>
      </TabPane>
      <TabPane label="项目公示记录" name="public" v-if="!allowEdit">

        <publicityRecords v-if="investmentId" :investId="investmentId" :allowEdit="allowEdit"></publicityRecords>
        <!-- <Table stripe :columns="columnData" :data="tableData"></Table> -->
      </TabPane>
      <TabPane label="招商范围" name="device">

        <InvestSpaceList v-if="investmentId" :investId="investmentId" :allowEdit="allowEdit"></InvestSpaceList>

      </TabPane>
      <TabPane label="招商主图" name="image">
        <CoverImage v-if="investmentId" :investId="investmentId" :allowEdit="allowEdit"></CoverImage>
      </TabPane>
      <TabPane label="详情介绍" name="introduce">
        <Row v-if="allowEdit">
          <i-col span="24" class="text-right">
            <i-button type="primary" class="m-r-5 m-t-10 m-b-5" size="small" @click="saveInvestContent"
              >保存详情</i-button
            >
          </i-col>
        </Row>
        <TinyEditor v-model="investHtml" :disabled="!allowEdit" ></TinyEditor>
      </TabPane>
    </Tabs>

  </div>
</template>

<script>

import TinyEditor from '@/components/common/TinyEditor'
import { HTMLEncode } from '@/utils'

import publicityRecords from './publicityRecords.vue'
import InvestSpaceList from './InvestSpaceList.vue'
import CoverImage from './CoverImage.vue'

import { toMoney } from '@/utils/wnumb_own'
/** new api */
import {
  getInvestmentProject,
  listSinInBusiness,
  orderRefund,
  editInvestContent
} from '@/api/invest/invest'
export default {
  components: {
    TinyEditor,
    InvestSpaceList,
    CoverImage,
    publicityRecords
  },
  props: {},
  data () {
    return {
      allowEdit: false,
      investmentInfo: {},
      deviceListModal: false,

      tableData: [],
      columnData: [
        {
          title: '经营品牌',
          key: 'brandName'
        },
        {
          title: '商标',
          key: 'brandType'
        },
        {
          title: '营业执照',
          key: 'businessLicense',
          render: (h, params) => {
            return h('div', [
              params.row.businessLicense ? h('img', {
                domProps: {
                  src:
                    params.row.businessLicense +
                    '?x-oss-process=image/resize,w_64'
                },
                on: {
                  click: () => {
                    window.open(params.row.businessLicense)
                  }
                },
                style: {
                  width: '64px',
                  cursor: 'pointer'
                }
              }) : '-'
            ])
          }
        },
        {
          title: '联系人',
          key: 'contactPerson'
        },
        {
          title: '联系电话',
          key: 'contactPhone'
        },
        {
          title: '联系邮箱',
          key: 'contactEmail'
        },
        {
          title: '经营规模（店铺数量）',
          key: 'storeNumber'
        },
        {
          title: '所属行业',
          key: 'industryName'
        },
        {
          title: '主营业务',
          key: 'businessScope'
        },
        { title: '报名时间', key: 'createTime' },
        { title: '保证金状态', key: 'orderStatusName' },
        { title: '状态', key: 'publicStatusName' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const refundButton = h(
              'a',
              {
                style: {
                  color: 'red',
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    let remark = ''
                    this.$Modal.confirm({
                      title: '客户退款',
                      render: (h) => {
                        return h('Input', {
                          props: {
                            value: remark,
                            placeholder: '请输入退款备注'
                          },
                          on: {
                            'on-change': (event) => {
                              remark = event.target.value
                            }
                          }
                        })
                      },
                      onOk: () => {
                        orderRefund({
                          investId: this.investmentId,
                          orderNo: params.row.orderNo,
                          remark: remark
                        }).then((res) => {
                          if (res && !res.errcode) {
                            this.$Notice.success({ desc: '退款成功' })
                            // window.location.reload()
                            this.initSinInBusiness()
                          }
                        })
                      }
                    })
                  }
                }
              },
              '退款'
            )

            return h('div', params.row.orderStatus !== 70 ? [refundButton] : [])
          }
        }
      ],

      investHtml: ''
    }
  },
  mounted () {},
  created () {
    if (this.investmentId) {
      this.initInvestmentDetail()
      this.initSinInBusiness()
      // this.initInvestmentDevices()
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    initInvestmentDetail () {
      const that = this
      getInvestmentProject({ investId: that.investmentId }).then((res) => {
        that.investmentInfo = res
        that.investHtml = res.content
        that.allowEdit = res.status === 1
      })
    },

    initSinInBusiness () {
      const that = this
      listSinInBusiness({ investId: that.investmentId }).then((res) => {
        that.tableData = res
      })
    },

    handleView (fileUrl) {
      window.open(fileUrl)
    },

    saveInvestContent () {
      editInvestContent({
        investId: this.investmentId,
        content: HTMLEncode(this.investHtml)
      }).then(res => {
        this.initInvestmentDetail()
      })
    }
  },
  watch: {
    investmentId () {
      this.initInvestmentDetail()
    },
    beginUpdate () {
      this.initInvestmentDetail()
    }
  },
  computed: {
    investmentId () {
      return this.$store.state.investmentManage.investmentId
    },
    beginUpdate () {
      return this.$store.state.investmentManage.beginUpdate
    }
  }
}
</script>
