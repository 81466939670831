import request from '@/utils/requestV2'
const qs = require('qs')

// 上传招商项目文件
export function uploadInvestFile (data) {
  return request({
    url: '/ooh-invest/v1/file/uploadInvestFile',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询文件列表
export function getFileList (data) {
  return request({
    url: '/ooh-invest/v1/file/getFileList',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除单个文件
export function deleteFile (data) {
  return request({
    url: '/ooh-invest/v1/file/deleteFile',
    method: 'post',
    data: qs.stringify(data)
  })
}
