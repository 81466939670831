<template>
  <Modal v-model="choiceSpaceModal" width="800" footer-hide>
    <p slot="header" class="text-center">添加租赁空间</p>
    <Row :gutter="8" class="m-b-5">
      <i-col span="16" class="m-b-5">
        <i-input
          size="small"
          v-model="query.keyword"
          placeholder="关键字"
        ></i-input>
      </i-col>
      <i-col span="4">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        >
      </i-col>
    </Row>
    <Table
      size="small"
      :columns="columnSpacedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
    <!-- <div slot="footer">
      <Button type="text" class="m-r-5" @click="choiceSpaceModal = false"
        >关闭</Button
      >
      <Button type="primary" @click="handleConfirmChk">确定</Button>
    </div> -->
  </Modal>
</template>

<script>
import { addLeaseSpace } from '@/api/invest/invest'
// import { spacePage } from '@/api/invest/invest'
import { spacePage } from '@/api/product/leaseSpace'
export default {
  props: {
    investmentId: {
      type: Number
    },
    chkSpaceArray: {
      type: Array
    }
  },
  data () {
    return {
      choiceSpaceModal: false,
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 10,
        keyword: ''
      },
      tableData: [],
      columnSpacedata: [
        {
          title: '编码',
          key: 'code'
        },
        {
          title: '空间名称',
          key: 'name'
        },
        { title: '类型', key: 'resourceTypeName' },
        {
          title: '备注',
          key: 'remark'
        },
        {
          title: '操作',
          align: 'center',
          width: 120,
          render: (h, params) => {
            const addButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.addSpace(params.row.id)
                  }
                }
              },
              '加入'
            )

            return h('div', [this.chkSpaceArray.map(res => { return res.spaceId }).includes(params.row.id) ? '-' : addButton])
          }
        }
      ]
    }
  },
  mounted () {},
  methods: {
    showModal () {
      this.initPageData()
      this.choiceSpaceModal = true
    },
    hideModal () {
      this.choiceSpaceModal = false
    },
    initPageData () {
      spacePage(this.query).then((response) => {
        this.total = response.totalRow
        this.tableData = response.list
        this.query.pageNumber = response.pageNumber
      })
    },

    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    addSpace (investSpaceId) {
      addLeaseSpace({ spaceIds: investSpaceId, investId: this.investmentId }).then(res => {
        // this.$store.commit('set_investment_update', new Date())
        if (res && res.success) {
          this.$emit('on-success')
        }
      })
    }

  },
  watch: {
  },
  computed: {
  }
}
</script>
